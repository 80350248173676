import {Button, Section,Heading} from 'react-bulma-components/dist'
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import React from "react";
import {observable, autorun} from 'mobx';
import {observer} from "mobx-react";
import {ProductStore, SupplierStore} from "../../../stores";
import Select from 'react-select';
import FetchView from "../../dumb/FetchView/FetchView";
import _ from "lodash"

@observer
class SupplierEdit extends React.Component {
    @observable localstate = {
        supplier: {
            id: null,
            Name:'',
            Language: 'DE',
            ContactName: '',
            ContactMail: ''
        },
        loading: false
    };

    componentDidMount() {
        autorun(() => {
            if (this.props.supplier.Name && !_.isEqual(this.localstate.supplier, this.props.supplier)) {
                this.localstate.supplier = {...this.props.supplier};
                this.localstate.supplier.Language = this.localstate.supplier.Language || 'DE';
            }
        });
    }

    onChange(k, v) {
        console.log(this.localstate);
        if(this.props.supplier) {
            this.props.supplier[k] = v;
        }
        this.localstate.supplier[k] = v;
    }

    async save() {
        const {supplier} = this.localstate;
        this.localstate.loading = true;
        let success = false;
        if (supplier.id !== null && supplier.id !== undefined) {
            // update existing supplier
            success = await SupplierStore.updateItem(supplier);
        } else {
            // create new supplier
            success = await SupplierStore.createItem(supplier);
        }
        this.localstate.loading = false;
        console.log(success);
        if (success && this.props.onSave) {
            this.props.onSave();
        }
    }

    render() {
        const {supplier} = this.localstate;
        return (
            <div>
                <FetchView store={ProductStore}>
                    <Section>
                        <Heading>{supplier.id ? 'Lieferant bearbeiten' : 'Lieferant anlegen'}</Heading>
                        <Field>
                            <Label>Firma</Label>
                            <Control  >
                                <Input type='text' value={supplier.Name} onChange={(e) => this.onChange('Name', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Sprache</Label>
                            <Control  >
                                <Select options={languageOptions}
                                        value={supplier.Language === 'EN' ?  languageOptions[1] : languageOptions[0]}
                                        onChange={(lang) => this.onChange('Language',lang.value)}
                                />
                            </Control>
                        </Field>
                        { /* <Field>
                            <Label>Produkte</Label>
                            <Control  >
                                <Select
                                        options={ProductStore.list.map(item => {
                                            return {value: item, label: item.TitleDE}
                                        })}
                                        isMulti
                                        onChange={(products) => this.onChange('Products',products)}
                                />
                            </Control>
                        </Field> */ }
                        <Field>
                            <Label>Ansprechpartner</Label>
                            <Control>
                                <Input type='text' value={supplier.ContactName} onChange={(e) => this.onChange('ContactName', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Mailadresse</Label>
                            <Control>
                                <Input type='text' value={supplier.ContactMail} onChange={(e) => this.onChange('ContactMail', e.target.value)}/>
                            </Control>
                        </Field>
                        <Button fullwidth loading={this.localstate.loading} onClick={this.save.bind(this)}>Speichern</Button>
                    </Section>
                </FetchView>
            </div>
        );
    }
}



const languageOptions = [{
    value: 'DE',
    label: 'DE'
}, {
    value: 'EN',
    label: 'EN'
}];

export default SupplierEdit;
