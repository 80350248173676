import React, {Component} from 'react';
import Navigation from "../../../shared/Navigation";

class Page extends Component {

    constructor(props) {
        super(props);
        Navigation.setLocation(props.location);
        Navigation.setHistoryObject(props.history);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    static getDerivedStateFromProps(props, state) {
        Navigation.setLocation(props.location);
        return state;
    }

    render() {
        const {children, location} = this.props;
        return children;
    }
}

export {Page};