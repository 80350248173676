import React, {Component} from 'react';
import NavigationBar from "../NavigationBar/NavigationBar";
import {Container, Section, Button, Modal} from "react-bulma-components/dist";
import ModalMailsVersenden from "./ModalMailsVersenden";
import FetchView from "../../dumb/FetchView/FetchView";
import {MailStore, ProductStore} from "../../../stores";
import {observer} from "mobx-react";
import { FaPaperPlane } from "react-icons/fa";
import {
    TableBody,
    Typography,
    Collapse,
    Box,
    Checkbox,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {observable} from 'mobx';
import StyledTableCell from "../../dumb/StyledTableCell/StyledTableCell";
import {IconWithText} from "../../dumb";
import Loader from 'react-bulma-components/lib/components/loader';

@observer
class HomeScreen extends Component{
    @observable localstate = {
        open: [],
        selected: [],
        showModal: false,
        page: 0,
        rowsPerPage: 10,
        selectAll: false,
        filtered: ProductStore.list,
        finishedFetch: false,
        showEmails: false,
        loadingEmails: false,
        emails: [],
        showOnlyWithPrice: false
    };

    componentDidUpdate() {
        if (ProductStore.status.fetched && !this.localstate.finishedFetch) {
            this.localstate.filtered = ProductStore.list;
            this.localstate.finishedFetch = true;
        }
    }

    changeOpen(id) {
        let open = this.localstate.open;
        if (open.indexOf(id) !== -1) {
            open.splice(open.indexOf(id), 1);
        } else {
            open.push(id);
        }
        this.localstate.open = open;
    }

    clickBox(item) {
        let selected = this.localstate.selected;
        if (selected.includes(item)){
            selected.splice(selected.findIndex(x => x.id === item.id), 1);
        } else {
            selected.push(item);
        }
        this.localstate.selected = selected;
    }

    appendSelected(a, b) {
        if (!a.includes(b)){
            a.push(b)
        }
    }

    selectAll() {
        const selected = this.localstate.selected;
        if (this.localstate.selectAll){
            this.localstate.selected = [];
        } else {
            ProductStore.list.map((item) => {
                if(item.suppliers.length > 0) {
                    this.appendSelected(selected, item);
                }
            });
            
            this.localstate.selected = selected;
        }
        this.localstate.selectAll = !this.localstate.selectAll;
    }

    openMailsVersenden() {
        let selected = this.localstate.selected;
        this.localstate.showModal = true;
    }

    closeMailsVersenden() {
        this.localstate.showModal = false;
    }

    makeColCell(curr, best) {
        if (!best) {
            return {};
        }
        if (Number(curr)<=Number(best)){
            return styles.good
        } else{
            return styles.bad
        }
    };

    handleChangePage(event, newPage) {
        this.localstate.page = newPage
    };

    handleChangeRowsPerPage(event) {
        this.localstate.rowsPerPage = event.target.value;
        this.localstate.page = 0;
    };

    handleLabelDisplayedRows({from, to, count}) {
        return(
            <span>{from} - {to} von {count}</span>
        );
    };

    getBestPrice(suppliers) {

        let sortedPrices = suppliers.slice().sort((a,b) => {
            if(!a.currentPrice) {
                return 1;
            }
            if(!b.currentPrice) {
                return -1;
            }
            if(a.currentPrice < b.currentPrice) {
                return -1;
            }
            
            if(a.currentPrice > b.currentPrice) {
                return 1;
            }

            return 0;
        })
        if(sortedPrices.length < 1) {
            return "";
        }

        const found = sortedPrices.find(x => x.currentPrice && x.currentPrice > 0);
        if (found) {
            return found.currentPrice;
        }
        return null;
    }

    handleChangeSearchBar(e) {
        let currentList = ProductStore.list;
        let newList = [];

        if (e.target.value !== "") {
            newList = currentList.filter(item => {
                const lc = item.Title.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
        } else
            newList = ProductStore.list;
        this.localstate.filtered = newList;
    }

    handleOnlyWithPrice(){
        // TODO: connect filters for search bar etc
        this.localstate.showOnlyWithPrice = !this.localstate.showOnlyWithPrice;
        if ( this.localstate.showOnlyWithPrice) {
            this.localstate.filtered = ProductStore.list.filter(x => {
                let hasPrice = false;
                x.suppliers.forEach(supplier => {
                    if (supplier.currentPrice && supplier.currentPrice > 0) {
                        hasPrice = true;
                    }
                })
                return hasPrice;
            });
        } else {
            this.localstate.filtered = ProductStore.list;
        }


    }

    closeEmails() {
        this.localstate.showEmails = false;
        this.localstate.loadingEmails = false;
        this.localstate.emails = [];
    }

    async getEmailForPrice(product, supplier, currentPrice) {
        if (product && supplier && currentPrice) {
            this.localstate.showEmails = true;
            this.localstate.loadingEmails = true;

            try {
                const result = await MailStore.getEmailForPrice(product, supplier, currentPrice);

                if (result && result.emails && result.emails.length) {
                    this.localstate.emails = result.emails;
                }
            } catch (error) {
                this.localstate.emails = [];
                console.error(error);
            }

            this.localstate.loadingEmails = false;
        }
    }

    renderEmail() {
        return (
            <div>
                {this.localstate.loadingEmails ?
                    <div style={styles.spinner}>
                        <Loader style={styles.loader} />
                    </div> :
                    this.localstate.emails.map(email => {
                        return (
                            <div style={styles.email}>
                                {email.text}

                                {email.attachments &&
                                <div style={styles.attachments}>
                                    {email.attachments.map(attachment => {
                                        return (
                                            <div style={styles.attachment}>
                                                <div style={styles.fileName}>
                                                    {attachment.filename}
                                                </div>

                                                <div style={styles.attachmentText}>
                                                    {attachment.text}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    render(){
        const {open, selected, showModal, page, rowsPerPage, showEmails, emails, showOnlyWithPrice} = this.localstate;
        const fakeSupplierPriceQuoteDate = '30.06.2020'; //TODO: add date for latest price quote for each supplier
        // const selectedItems = selected.map(id => ProductStore.getItemById(id));
        return (
            <div>
                <NavigationBar title='Home'>
                    <input column="left" type="text" className="input" onChange={(e) => this.handleChangeSearchBar(e)} placeholder="Suche..." />

                    <Button column="right" onClick={() => this.openMailsVersenden()} disabled={selected.length === 0}>
                        <IconWithText icon={<FaPaperPlane/>} text="Mails versenden"/>
                    </Button>
                </NavigationBar>
                <Modal show={showModal} onClose={this.closeMailsVersenden.bind(this)} closeOnBlur={true}>
                    <Modal.Content style={styles.innerModal}>
                        <ModalMailsVersenden selected={selected}></ModalMailsVersenden>

                    </Modal.Content>
                </Modal>

                <Modal show={showEmails} onClose={this.closeEmails.bind(this)} closeOnBlur>
                    <Modal.Content style={styles.innerModal}>
                        {this.renderEmail()}
                    </Modal.Content>
                </Modal>

                <Container>

                    <Section>
                        <FetchView store = {ProductStore}>
                            <Button isSelected={showOnlyWithPrice}  onClick={() => this.handleOnlyWithPrice()}>{showOnlyWithPrice ? "Show all products": "Only show products with prices"}</Button>
                            <Table style={{paddingTop: '15px'}} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Button onClick={() => this.selectAll()}>
                                                Alle auswählen
                                            </Button>
                                        </TableCell>
                                        <TableCell align='left'>Produkt</TableCell>
                                        <TableCell align='left'>Bester EK</TableCell>
                                        <TableCell align='left'>Aktueller EK</TableCell>
                                    </TableRow>
                                </TableHead>
                                {this.localstate.filtered
                                    .slice(page * rowsPerPage, page*rowsPerPage+rowsPerPage)
                                    .map(item => {
                                        return (
                                            <TableBody key={item.id.toString()}>
                                                <TableRow>
                                                    <TableCell style={styles.tableCell} padding='checkbox'>
                                                        <Checkbox disabled={item.suppliers.length < 1} onClick={() => this.clickBox(item)} checked={selected.includes(item)} />
                                                    </TableCell>
                                                    <StyledTableCell padding='checkbox'>
                                                        <IconButton aria-label='expand row' size='small' onClick={() => this.changeOpen(item.id)}>
                                                            {open.indexOf(item.id) !== -1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left'>{item.Title}</StyledTableCell>
                                                    <StyledTableCell align='left'> {this.getBestPrice(item.suppliers)} </StyledTableCell>
                                                    <StyledTableCell align='left' style={this.makeColCell(item.CurrentPrice, this.getBestPrice(item.suppliers))}>{item.CurrentPrice}</StyledTableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingBottom: 0, paddingTop:0}} colSpan={5}>
                                                        <Collapse in={open.indexOf(item.id) !== -1} unmountOnExit>
                                                            <Box margin={4}>
                                                                <Typography variant="h6">
                                                                    Lieferantendetails
                                                                </Typography>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Lieferant</TableCell>
                                                                            <TableCell>Datum</TableCell>
                                                                            <TableCell>aktueller Preis</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    {item.suppliers.map(({
                                                                                             id,
                                                                                             Name,
                                                                                             currentPrice,
                                                                                             currentPriceCreatedAt
                                                                                         }) => (
                                                                        <TableBody key={id.toString()} onClick={async () => {
                                                                            await this.getEmailForPrice(item.id, id, currentPrice);
                                                                        }}>
                                                                            <TableRow>
                                                                                <TableCell>{Name}</TableCell>
                                                                                <TableCell>{currentPriceCreatedAt ? new Date(currentPriceCreatedAt).toLocaleDateString() : "Kein Angebot erhalten"}</TableCell>
                                                                                <TableCell>{currentPrice}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    ))}
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    }
                                )}
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100, { label: 'Alle anzeigen', value: this.localstate.filtered.length }]}
                                colSpan={5}
                                component="div"
                                count={this.localstate.filtered.length}
                                rowsPerPage={rowsPerPage}
                                labelRowsPerPage="Zeilen pro Seite"
                                labelDisplayedRows={this.handleLabelDisplayedRows.bind(this)}
                                page={page}
                                onChangePage={this.handleChangePage.bind(this)}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                            />
                        </FetchView>
                    </Section>
                </Container>
            </div>
        );
    }
}


const styles = {
    bad: {
        backgroundColor: 'LightCoral',
        color: 'DarkRed'
    },
    good:{
        backgroundColor: 'LightGreen',
        color: 'DarkGreen'
    },
    tableCell: {
        borderBottom: 0
    },
    innerModal: {
        backgroundColor: "white"
    },
    email: {
        whiteSpace: 'pre-line',
        padding: '1em',
        margin: '1em',
        borderWidth: 1,
        borderStyle: 'solid'
    },
    loader: {
        width: 150,
        height: 150
    },
    spinner: {
        padding: '6em',
        display: 'flex',
        justifyContent: 'center'
    },
    attachments: {
        marginTop: '1em',
        backgroundColor: 'darkgrey',
        padding: '1em'
    },
    attachment: {
        padding: '0.5em',
        backgroundColor: 'lightgray',
        marginTop: '0.5em',
        marginBottom: '0.5em'
    },
    fileName: {
        marginBottom: '1em',
        fontWeight: 'bold'
    },
    attachmentText: {
        whiteSpace: 'pre-line'
    }
}

export default HomeScreen;
