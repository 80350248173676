import {Button, Section,Heading} from 'react-bulma-components/dist'
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import React from "react";
import {observable, autorun} from 'mobx';
import {observer} from "mobx-react";
import {ProductStore, TemplateStore} from "../../../stores";
import Select from 'react-select';
import FetchView from "../../dumb/FetchView/FetchView";
import _ from "lodash"
import {TextareaAutosize} from "@material-ui/core";

@observer
class TemplateEdit extends React.Component {
    @observable localstate = {
        template: {
            id: null,
            name:'',
            subject: '',
            content: '',
            language: ''
        },
        loading: false,
        showModal: false,
        tplNameUnique: true
    };

    componentDidMount() {
        autorun(() => {
            if (this.props.template.name && !_.isEqual(this.localstate.template, this.props.template)) {
                this.localstate.template = {...this.props.template};
                this.localstate.template.language = this.localstate.template.language || 'DE';
            }
        });
    }

    onChange(k, v) {
        if(this.props.template) {
            this.props.template[k] = v;
        }
        this.localstate.template[k] = v;
    }

    async save() {
        const {template} = this.localstate;
        this.localstate.loading = true;
        let success = false;
        if (template.id !== null && template.id !== undefined) {
            // update existing template
            success = await TemplateStore.updateItem(template);
        } else {
            // create new template
            success = await TemplateStore.createItem(template);
        }
        this.localstate.loading = false;
        if (success && this.props.onSave) {
            this.props.onSave();
        }
    }



    render() {
        const {template} = this.localstate;
        // check whether new template name already exists.

        return (
            <div>
                <FetchView store={TemplateStore}>
                    <Section>
                        <Heading>{template.id ? 'Edit Template' : 'Create Template'}</Heading>
                        <Field>
                            <Label>Template Name</Label>
                            <Control  >
                                <Input type='text' value={template.name} onChange={(e) => this.onChange('name', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Language</Label>
                            <Control  >
                                <Select options={languageOptions}
                                        value={template.language === 'EN' ?  languageOptions[1] : languageOptions[0]}
                                        onChange={(lang) => this.onChange('language',lang.value)}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <Label>Subject</Label>
                            <Control>
                                <Input type='text' value={template.subject} onChange={(e) => this.onChange('subject', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Content</Label>
                            <Control>
                                <TextareaAutosize
                                    style={{height:"500px", width:"100%"}}
                                    type='text'
                                    value={template.content}
                                    onChange={(e) => this.onChange('content', e.target.value)}
                                />
                            </Control>
                        </Field>
                        <Button fullwidth loading={this.localstate.loading} onClick={this.save.bind(this)}>Speichern</Button>
                    </Section>
                </FetchView>
            </div>
        );
    }
}



const languageOptions = [{
    value: 'DE',
    label: 'DE'
}, {
    value: 'EN',
    label: 'EN'
}];

export default TemplateEdit;