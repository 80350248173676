import Store from './Store';
import RestClient from "../shared/Network/RestClient";

const MailStore = new Store('mail');

MailStore.getEmailForPrice = (product, supplier, price) => {
    return new Promise(async (resolve, reject) => {
        const request = RestClient.prepareRequest('GET', `${MailStore.url}/priceEmail?product=${product}&supplier=${supplier}&price=${price}`);

        try {
            const response = await request.send();
            if (response.success) {
                return resolve(response.data);
            } else {
                console.error(response.error);

                return reject(response.error);
            }
        } catch (error) {
            return reject(error);
        }
    })
}

export { MailStore };