import {observer} from "mobx-react";
import {Component} from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import {Modal, Heading,Section, Box, Button, Hero, Columns, Container} from 'react-bulma-components/dist'
import {IconWithText} from "../../dumb";
import {Link} from "react-router-dom";
import React from "react";
import FetchView from "../../dumb/FetchView/FetchView";
import { FaPen, FaTrash,FaPlusSquare } from 'react-icons/fa';
import {SupplierStore, TemplateStore} from "../../../stores";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import SupplierEdit from '../SupplierEdit/SupplierEdit';
import {observable} from 'mobx';
import {RiDatabase2Line} from "react-icons/all";
import {Language} from "@material-ui/icons";
import TemplateEdit from "../TemplateEdit/TemplateEdit";

@observer
class TemplateScreen extends Component {
    @observable localstate = {
        showModal: false,
        template: null,
        finishedFetch: false,
        filter: null
    };

    shortenString(s, charval){
        if (s === null || s.length <= charval){
            return s
        } else {
            return s.slice(0, charval) + "..."
        }
    };

    closeModal() {
        this.localstate.showModal = false;
    };

    deleteTemplate(templ) {
        var confirmed = confirm('Are you sure you want to delete this template?');
        if (confirmed) {
            TemplateStore.deleteItem(templ);
        }
    }

    editTemplate(tpl){
        this.localstate.showModal = true;
        this.localstate.template = tpl
    }


    render() {
        const {showModal, template} = this.localstate
        const emptyTemplate = {
            id: null,
            name:'',
            subject: '',
            content: '',
            language: ''
        }
        return (
            <div>
                <NavigationBar title='Templates'>
                    <Button column="right" onClick={() => this.editTemplate(emptyTemplate)}> <IconWithText icon={<FaPlusSquare/>} text='Template hinzufügen'/></Button>
                </NavigationBar>
                <Modal show={showModal} onClose={this.closeModal.bind(this)} closeOnBlur={true}>
                    <Modal.Content style={styles.innerModal}>
                        <TemplateEdit template={template} onSave={this.closeModal.bind(this)} />
                    </Modal.Content>
                </Modal>
                <Container>
                    <Section>
                        <FetchView store={TemplateStore}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Template Name</TableCell>
                                        <TableCell align="left">Subject</TableCell>
                                        <TableCell align="left">Content</TableCell>
                                        <TableCell align="left">Language</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {TemplateStore.list.map(item => (
                                    <TableRow>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{this.shortenString(item.subject, 60)}</TableCell>
                                        <TableCell>{this.shortenString(item.content, 120)}</TableCell>
                                        <TableCell>{item.language}</TableCell>
                                        <TableCell align="right">
                                            <Button onClick={() => this.editTemplate(item)}><FaPen/></Button>
                                            <Button onClick={() => this.deleteTemplate(item)}><FaTrash/></Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </FetchView>
                    </Section>
                </Container>
            </div>);
    }


}

const styles = {
    innerModal: {
        backgroundColor: 'white'
    }
}


export default TemplateScreen;