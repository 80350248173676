import {Link} from "react-router-dom";
import Authentication from "../../../shared/Authentication";
import React, {Component} from "react";
import { FaEnvelope, FaCog, FaSignOutAlt } from 'react-icons/fa';
import Navigation from "../../../shared/Navigation";
import {observer} from 'mobx-react';
import { Container} from 'react-bulma-components/dist'

@observer
class Header extends Component {
    render() {
        const url = Navigation.location.pathname;
        const activeStyle = {...styles.menuItem, ...styles.active};
        let tabStyles = {
            products: styles.menuItem,
            home: styles.menuItem,
            mails: styles.menuItem,
            suppliers: styles.menuItem,
            templates: styles.menuItem
        };

        if (url && url.indexOf) {
            if (url.indexOf('/products') !== -1) {
                tabStyles["products"] = activeStyle;
            } else if (url.indexOf('/mails') !== -1) {
                tabStyles["mails"] = activeStyle;
            } else if (url.indexOf('/suppliers') !== -1) {
                tabStyles["suppliers"] = activeStyle;
            } else if(url.indexOf('/templates') !== -1){
                tabStyles["templates"] = activeStyle
            } else {
                tabStyles["home"] = activeStyle;
            }
        }

        return (<div style={styles.header}>
            <Container breakpoint='fullhd' style={styles.headContainer}>
            <div style={styles.headerLeft}>
                <div style={styles.logo}>Sourcing-Tool</div>
                <div style={styles.menu}>
                    <Link style={styles.headerMenuLink} to='/'><div style={tabStyles.home}>Home</div></Link>
                </div>
                <div style={styles.menu}>
                    <Link style={styles.headerMenuLink} to='/products'><div style={tabStyles.products}>Produkte</div></Link>
                </div>
                <div style={styles.menu}>
                    <Link style={styles.headerMenuLink} to='/mails'><div  style={tabStyles.mails}>Mails</div></Link>
                </div>
                <div style={styles.menu}>
                    <Link style={styles.headerMenuLink} to='/suppliers'><div  style={tabStyles.suppliers}>Lieferanten</div></Link>
                </div>
                <div style={styles.menu}>
                    <Link style={styles.headerMenuLink} to='/templates'><div  style={tabStyles.templates}>Templates</div></Link>
                </div>
            </div>

            <div style={styles.headerRight}>
                <div style={styles.headerIcon}><FaCog color="white" size={22} /></div>
                <div style={styles.headerIcon}>
                    <FaSignOutAlt color="white" size={22} onClick={() => Authentication.logout()}>Logout</FaSignOutAlt>
                </div>
            </div>
            </Container>
        </div>);
    }
}

const styles = {
    brandHeader: {
        height: 300,
        overflow: 'hidden',
        backgroundImage: 'url("https://koro.imgix.net/media/image/58/f1/f1/Mango-Bowl-2.jpg?auto=compress,format&w=1280&h=1280&dpr=2")',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    },
    active: {
      background: '#7171d4',
        borderRadius: 50
    },
    menu: {
        display: 'flex',
        flexDirection: 'row'
    },
    menuItem: {
        padding: 10,
        color: 'white',
        paddingLeft: 30,
        paddingRight: 30,
        cursor: 'pointer'
    },
    logo: {
        fontFamily: 'Pacifico',
        fontSize: 25,
        paddingRight: 20
    },
    headerLeft: {
        display: 'flex',
    },
    headerRight: {
        display: 'flex'
    },
    headerIcon: {
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer'
    },
    headerMenuLink: {
        color: 'white'
    },
    header: {
        backgroundColor: 'darkblue',
        display: 'flex',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        color: 'white',
        position: 'fixed',
        width: '100%',
        left: 0,
        zIndex: 10,

    },
    headContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    }
}


export default Header;