import {observable, computed} from 'mobx';
import RestClient from "../shared/Network/RestClient";

class Store {
    @observable list = [];
    url = null;
    @observable
    status = {
       fetched: false,
       loading: false,
       error: null
    };

    constructor(url) {
        this.url = url;
        this.removeItem = this.removeItem.bind(this);
    }

    getItemById(id) {
        return this.list.find(x => x.id === id);
    }

    async fetchData() {
        this.status.error = null;
        this.status.loading = true;
        const request = RestClient.prepareRequest('GET', this.url);
        try {
            const response = await request.send();
            this.status.loading = false;
            if (response.success) {
                this.status.fetched = true;
                this.list = response.data || [];
            } else {
                this.status.error = response.error;
            }
        } catch (e) {
            this.status.error = e;
        }
    }

    async updateItem(data) {
        let item = this.list.find(x => x.id === data.id);
        const index = this.list.indexOf(item);
        item = {...item, ...data};

        const request = RestClient.prepareRequest('PUT', this.url + '/' + item.id);
        request.setData(item);
        const response = await request.send();
        if (response.success) {
            this.list[index] = response.data;
        }
        return response.success;
    }

    removeItem(item) {
        // this.list = this.list.splice(0,1)
        this.list = this.list.filter(x => x !== item);
    }

    async createItem(item) {
        const request = RestClient.prepareRequest('POST', this.url);
        request.setData(item);
        const response = await request.send();
        if (response.success) {
            this.list.push(response.data);
        }
        return response.success;
    }

    async deleteItem(item) {
        let itemInList = this.list.find(x => x.id === item.id);
        const index = this.list.indexOf(itemInList);
        const request = RestClient.prepareRequest('DELETE', this.url + '/' + item.id);
        const response = await request.send();
        if (response.success) {
            this.list.splice(index, 1);
        }
        return response.success;
    }


    async addItem(item) {
        this.list.push(item);
    }

    initWithData(data) {
        this.list = data;
    }
}

export default Store;