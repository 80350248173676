import React from "react";

function Spacer ({children}) {
    return (<div style={styles.spacer} />);
}

const styles = {
    spacer: {
        height: 1,
        width: '100%',
        background: 'rgb(202, 202, 202, 0.5)',
        marginTop: 15,
        marginBottom: 15
    }
};

export {Spacer};