import RestClient from "./Network/RestClient";
import {observable} from 'mobx';

class Authentication {
    @observable
    status = {
        loading: false,
        error: false,
        loggedIn: false
    };
    @observable
    user = {
      firstName: null,
        lastName: null
    };
    token = null;

    constructor(props) {
        const storage = localStorage.getItem('userData');
        if (storage) {
            this.setUserData(JSON.parse(storage));
        }
        this.logout = this.logout.bind(this);
    }

    setUserData(userData) {
        localStorage.setItem('userData', JSON.stringify(userData));
        this.user = userData.user;
        this.token = userData.token;
        this.status.loggedIn = true;
    }

    async login(email, password) {
        this.status.loading = true;
        const request = RestClient.prepareRequest('POST', 'auth/login');
        request.setData({email, password});
        try {
            const response = await request.send();
            console.log(response);
            this.status.loading = false;
            if (response.success) {
                this.setUserData(response.data);
                return true;
            }
        }
        catch (e) {
            alert(e);
            this.status.loading = false;
            this.status.error = e;
            return false;
        }
    }

    isLoggedIn() {
        return this.status.loggedIn;
    }

    logout() {
        localStorage.setItem('userData', '');
        this.status.loggedIn = false;
        console.log("logged out")
    }
}

export default new Authentication();