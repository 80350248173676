import React, {Component} from 'react';
import {Modal, Heading,Section, Button, Hero, Columns, Container, Box, Icon} from 'react-bulma-components/dist'
import { FaEnvelope, FaTags, FaReceipt, FaShoppingBag, FaTruck } from 'react-icons/fa';
import {  Field, Input,Textarea, Label, Control,Checkbox } from 'react-bulma-components/lib/components/form';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import CreatableSelect from 'react-select/creatable';
import ImageUploader from "react-images-upload";


const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];


@observer
class ProductCreationBasics extends Component {
    @observable localstate = {
        title: '',
        SKU: '',
        SalesDesignation: '',
        certificates: [],
        pictures: [],
        EAN: ''
    };

    constructor(props) {
        super(props);
        const {product = {}} = props.context;
        Object.keys(product).forEach(key => {
            this.localstate[key] = product[key];
        });

    }

    onChange(k,v) {
        if (v && v.target) {
            v = v.target.value;
        }
        this.localstate[k] = v;
    }

    render() {
        const {SalesDesignation, SKU, TitleDE, TitleEN, CurrentPrice} = this.localstate;
        return (<div>
                        <Field>
                            <Label>Artikelnummer</Label>
                            <Control  >
                                <Input type='text' value={SKU} onChange={(e) => this.onChange('SKU', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Verkehrsbezeichnung EN</Label>
                            <Control  >
                                <Input type='text' value={SalesDesignation} onChange={(e) => this.onChange('SalesDesignation', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Verkehrsbezeichnung DE</Label>
                            <Control  >
                                <Input type='text'  onChange={(e) => this.onChange('SalesDesignationDE', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Title DE</Label>
                            <Control  >
                                <Input type='text' value={TitleDE} onChange={(e) => this.onChange('TitleDE', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Title EN</Label>
                            <Control  >
                                <Input type='text'   value={TitleEN} onChange={(e) => this.onChange('TitleDE', e.target.value)}/>
                            </Control>
                        </Field>
                        <Field>
                            <Label>Aktueller EK</Label>
                            <Control  >
                                <Input type='text'   value={CurrentPrice} onChange={(e) => this.onChange('TitleDE', e.target.value)}/>
                            </Control>
                        </Field>

            </div>)
    }
}

export default ProductCreationBasics;