import React, {Component} from 'react';
import {Modal, Heading,Section, Button, Hero, Columns, Container, Box, Icon} from 'react-bulma-components/dist'
import { FaEnvelope, FaTags, FaReceipt, FaShoppingBag, FaTruck } from 'react-icons/fa';
import {  Field, Input,Textarea, Label, Control,Checkbox } from 'react-bulma-components/lib/components/form';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import CreatableSelect from 'react-select/creatable';
import ImageUploader from "react-images-upload";
import ReactSelect from "react-select";
import FetchView from "../../../dumb/FetchView/FetchView";
import {getSuppliersForProduct} from "../../../../stores/ProductSupplierStore";


@observer
class ProductSuppliers extends Component {
    @observable localstate = {};
    supplierStore = null;

    componentDidMount() {
        this.supplierStore = getSuppliersForProduct(this.props.product);
    }

    onChange(k,v) {
        if (v && v.target) {
            v = v.target.value;
        }
        this.localstate[k] = v;
    }

    render() {
        const {price, uvp} = this.localstate;
        return (<div>
            {this.supplierStore && <FetchView store={this.supplierStore}>
                <p>here we should render an editable list of all suppliers for this product</p>
            </FetchView>}
        </div>)
    }
}

export default ProductSuppliers;