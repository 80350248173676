import {Link} from "react-router-dom";
import Authentication from "../../../shared/Authentication";
import React, {Component} from "react";
import { FaEnvelope, FaCog, FaSignOutAlt } from 'react-icons/fa';
import {Modal, Heading,Section, Button, Hero, Columns, Container, Box, Icon} from 'react-bulma-components/dist'
import { FaBackward, FaTags, FaReceipt, FaShoppingBag, FaTruck, FaChevronLeft } from 'react-icons/fa';


class NavigationBar extends Component {
    render() {
        const {title, column, children} = this.props;
        const left = [];
        React.Children.forEach(this.props.children, (child, i) => {
            if (child.props.column === "left") {
                left.push(child)
            }
        });
        const right = [];
        React.Children.forEach(this.props.children, (child, i) => {
            if (child.props.column === "right") {
                right.push(child)
            }
        });
        return (<div style={styles.navigationBar}>
            <Container size='fullhd'>
                <Columns>
                    <Columns.Column size={3} style={styles.searchBar} >
                        {left}
                    </Columns.Column>
                    <Columns.Column size={6} style={styles.pagetitle}>
                        <Heading>{title}</Heading>
                    </Columns.Column>
                    <Columns.Column size={3} style={styles.buttons}>
                        {right}
                    </Columns.Column>
                </Columns>
            </Container>
        </div>);
    }
}


const styles = {
    vertialSelection: {
        display:'flex',
        padding: 10,
        paddingTop: 15,
        paddingBottom: 15,
        alignItems: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #eee'
    },
    pagetitle: {
        display: 'flex',
        justifyContent: 'center'
    },
    dot: {
        height: 10,
        width: 10,
        backgroundColor: 'blue',
        display: 'flex',
        marginLeft: 10,
        borderRadius: 100

    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    navigationBar: {
        display: 'flex',
        background: 'rgba(238, 238, 238, 0.54)',
        justifyContent:'center',
        height: 80,
        alignItems:'center',
        borderTop: '1px solid #eee',
        borderBottom: '1px solid #eee',

    },
    searchBar: {
        paddingLeft: 40,
        alignItems: 'center',
        display: 'flex'
    },

    icon: {
        display: 'flex',
        paddingRight: 10,
        alignItems: 'center',
        justifyContent: 'center'
    }
}


export default NavigationBar;