import React, {Component}  from "react";
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import Loader from 'react-bulma-components/lib/components/loader';

@observer
class FetchView extends Component {

    componentDidMount() {
        const {store} = this.props;
        if (!store.status.fetched) {
            store.fetchData();
        }
    }

    render() {
        const {store, children = null, showSpinner = true} = this.props;
        const {loading, fetched, error} = store.status;
        if (loading) {
            return (<div style={styles.loadingContainer}>{showSpinner && <Loader style={styles.loader} />}</div>);
        } else if (fetched) {
            return children;
        } else if (error) {
            return <div>{error.message}</div>;
        } else {
            return null;
        }
    }
}

const styles = {
    loadingContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    loader: {
        width: 150,
        height: 150
    }
}

export default FetchView;