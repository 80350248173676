
import React, {Component} from 'react';
import {getStoreForBrand} from "../../../stores";
import {Modal, Heading,Section, Button, Hero, Columns, Container, Box, Icon} from 'react-bulma-components/dist'
import { FaEnvelope, FaTags, FaReceipt, FaShoppingBag, FaTruck } from 'react-icons/fa';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {  Field, Input,Textarea, Label, Control,Checkbox } from 'react-bulma-components/lib/components/form';
import Stepper from '../../dumb/Stepper/Stepper';
import ProductCreationBasics from '../ProductCreation/ProductCreationBasics/ProductCreationBasics';
import FetchView from "../../dumb/FetchView/FetchView";
import ProductSuppliers from "../ProductCreation/ProductSuppliers/ProductSuppliers";
import NavigationBar from "../NavigationBar/NavigationBar";
import {ProductStore} from '../../../stores/';


const steps = [{
    icon:<FaShoppingBag />,
    title: 'Basisdaten',
    component:ProductCreationBasics
}, {
    icon:<FaReceipt />,
    title: 'Lieferanten',
    component:ProductSuppliers
}];

@observer
class ProductDetailView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {product} = this.props;
        let item = ProductStore.list.find(x => x.id == product);
        console.log(ProductStore.list);
        return (<div>
            <NavigationBar title={item ?   'Produktdetails: ' + item.TitleDE : 'Produktdetails'} backURL={'../'} />
            <FetchView store={ProductStore}>
                {item && <Stepper steps={steps} context={{product:item}} allowJumps/>}
            </FetchView>
        </div>);
    }
}

const styles = {
    vertialSelection: {
        display:'flex',
        padding: 10,
        paddingTop: 15,
        paddingBottom: 15,
        alignItems: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #eee'
    },
    dot: {
        height: 10,
        width: 10,
        backgroundColor: 'blue',
        display: 'flex',
        marginLeft: 10,
        borderRadius: 100

    },
    buttons: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        display: 'flex',
        paddingRight: 10,
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default ProductDetailView;