import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {autorun} from 'mobx';
import {
    BrowserRouter as Router,
    HashRouter,
    Switch,
    Route,
    Redirect,
    useHistory,
    withRouter,
    useLocation
} from "react-router-dom";
import Login from "./components/screen/Login/Login";
import Authentication from "./shared/Authentication";

import ProductsScreen from "./components/screen/ProductsScreen/ProductsScreen";

import NotFound from "./components/screen/NotFound/NotFound";
import PasswordForgotten from "./components/screen/PasswordForgotten/PasswordForgotten";
import Header from "./components/screen/Header/Header";

import 'animate.css';
import 'public.css';
import {Page} from "./components/dumb";
import ProductDetailView from "./components/screen/ProductDetailView/ProductDetailView";
import SupplierScreen from "./components/screen/SupplierScreen/SupplierScreen";
import HomeScreen from "./components/screen/HomeScreen/HomeScreen";
import MailsScreen from "./components/screen/MailsScreen/MailsScreen";
import TemplateScreen from "./components/screen/TemplateScreen/TemplateScreen";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



@observer
class App extends Component {
    status = {
        loggedIn: false
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (

            <HashRouter>
                <Switch>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>


                    <Route exact path="/forgotten">
                        <PasswordForgotten/>
                    </Route>

                    <PrivateRoute exact path="/" component={HomeScreen} />

                    <PrivateRoute exact path="/products" component={ProductsScreen} />

                    <PrivateRoute exact path="/mails" component={MailsScreen}/>

                    <PrivateRoute exact path="/suppliers" component={SupplierScreen} />

                    <PrivateRoute exact path="/products/:product" component={ProductDetailView} />

                    <PrivateRoute exact path="/templates" component={TemplateScreen}/>


                    <Route path="*">
                        <NotFound loggedIn={Authentication.status.loggedIn} />
                    </Route>
                </Switch>
                <ToastContainer />
        </HashRouter>)
    }
}



function PrivateRoute({ component, children, ...rest }) {
    let history = useHistory();

    autorun(() => {
        if (!Authentication.status.loggedIn) {
            history.push('/login');
        }
    }, 'LogoutRouter');

    return (<div>
            <Header/>
            <div style={{paddingTop: 64}}>
        <Route
            {...rest}
            render={(props) =>
                Authentication.status.loggedIn ? (
                    <Page location={props.location} history={history}>{React.createElement(component, props.match ? {...props.match.params, location: props.location} : {location: props.location})}</Page>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }

        /></div></div>
    );
}


function LoginPage() {
    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } };

    autorun(() => {
        if (Authentication.status.loggedIn) {
            history.replace(from);
        }
    }, 'AuthenticationRouter');

    return (
        <Login/>
    );
}

export default App;