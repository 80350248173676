import Authentication from "../Authentication";

const axios = require('axios').default;

import config from '../../config/config';
import REST_STATES from './RestStates';
import REST_ERRORS from './RestErrors';


class RestClientClass {
    requests = [];

    prepareRequest(method, url) {
        return new Request(method, url);
    }

    handleFailedRequests() {
        // go through array, remove completed ones, retry failed ones
    }

    buildQuery(request) {

    }

    async sendRequest(request) {
        this.requests.push(request);
        console.log({
            method: request.method,
            url: config.API.ENDPOINT + request.url,
            data: request.body
        });

        let response = await axios({
            method: request.method,
            url: config.API.ENDPOINT + request.url,
            headers: {'Authorization': 'Bearer ' + Authentication.token},
            data: request.body
        });

        console.log(response.data);

        return response.data;
    }
};

const RestClient = new RestClientClass();


class Request {
    method = null;
    url = null;
    body = null;
    status = {
        status: REST_STATES.PREPARED,
        repeatOnFailure: true,
        error: null,
        trials: 0
    };

    constructor(method, url) {
        this.method = method;
        this.url = url;
    }

    getStatus() {
       return this.status;
    }

    setRepeatOnFailure(bool) {
        this.status.repeatOnFailure = bool;
    }


    setData(body) {
        this.body = body;
    }

    async send() {
        return await RestClient.sendRequest(this);
    }
}

export default RestClient;