import React, {Component} from 'react';
import {Box, Table, TableBody, Typography} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableRow from "@material-ui/core/TableRow/TableRow";
import {Button} from "react-bulma-components";
import {observer} from "mobx-react";
import {observable} from 'mobx';
import RestClient from "../../../shared/Network/RestClient";
import {MailStore} from "../../../stores/MailStore";
import { toast } from 'react-toastify';

@observer
class ModalMailsVersenden extends Component{
    @observable localstate = {
        disableSendBtn: false
    };
    async sendRequests() {
        this.localstate.disableSendBtn = true;
        let ids = [];
        for (let product of this.props.selected) {
            ids.push(product.id);
        }
        const request = RestClient.prepareRequest('POST', MailStore.url + '/pricerequest');
        request.setData({productIds: ids});
        try {
            const response = await request.send();
            if(response.success) {
                toast.success("Price requests were sent successfully.");
            } else {
                toast.error("An error occured "+response.error);
                console.log(response.error);
            }
        } catch (e) {
            toast.error("An error occured "+e);
            console.error(e);
        }
    }

    render(){
        const {selected} = this.props;
        return(
            <Box margin={4}>
                <Typography variant="h6">
                    Für folgende {selected.length} Produkte Preisanfragen schicken?
                </Typography>
                <Box margin={4}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableCell>ID</TableCell>
                            <TableCell>Produktname</TableCell>
                            <TableCell>Aktueller EK</TableCell>
                        </TableHead>
                        <TableBody>
                            {selected.map(item => (
                                <TableRow>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.Title}</TableCell>
                                    <TableCell>{item.CurrentPrice}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                <Button disabled={this.localstate.disableSendBtn} onClick={() => this.sendRequests()}>Preisanfragen schicken</Button>
            </Box>
        )
    }
}

export default ModalMailsVersenden;