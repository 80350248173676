import React, {Component} from "react";
import {Box, Container, Typography} from "@material-ui/core";
import {Modal} from "react-bulma-components";

class AttachmentsModal extends Component{


    writeText(textstring) {
        return(
            textstring.split('\n').map(item => (
                <p>{item}</p>
            ))
        )
    }

    render() {
        const {attachment} = this.props;
        return(
            <Modal.Content style={styles.innerModal}>
                <Box margin={4}>
                    <div>
                        <Typography variant={"h6"}>
                            Anhang: {attachment.filename}
                        </Typography>
                        <Typography variant="body1">
                            {this.writeText(attachment.text)}
                        </Typography>
                    </div>
                </Box>
            </Modal.Content>
        )
    }
}

const styles = {
    innerModal:{
        backgroundColor: "white"
    }
}

export default AttachmentsModal;