import {observer} from "mobx-react";
import {Component} from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import {Modal, Heading,Section, Box, Button, Hero, Columns, Container} from 'react-bulma-components/dist'
import {IconWithText} from "../../dumb";
import {Link} from "react-router-dom";
import React from "react";
import FetchView from "../../dumb/FetchView/FetchView";
import { FaPen, FaTrash,FaPlusSquare } from 'react-icons/fa';
import {ProductStore, SupplierStore} from "../../../stores";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {Control, Field, Input, Label} from "react-bulma-components/lib/components/form";
import SupplierEdit from '../SupplierEdit/SupplierEdit';
import {observable} from 'mobx';

@observer
class SupplierScreen extends Component {
    @observable localstate = {
        showModal: false,
        supplier: null,
        finishedFetch: false,
        filter: null
    };

    componentDidUpdate() {
        if (ProductStore.status.fetched && !this.localstate.finishedFetch) {
            this.localstate.filtered = ProductStore.list;
            this.localstate.finishedFetch = true;
        }
    }

    editSupplier(supplier) {
        this.localstate = {
            showModal: true,
            supplier: supplier
        }
    }

    deleteSupplier(supplier) {
        var confirmed = confirm('Möchtest du diesen Lieferanten wirklich löschen?');
        if (confirmed) {
           SupplierStore.deleteItem(supplier);
        }
    }

    closeModal() {
        this.localstate.showModal = false;
    }

    applyFilters(list) {
        let {filter} = this.localstate;
        let newList = list;
        if (filter) {
            newList = list.filter(item => {
                filter = filter.toLowerCase();
                let object = null;
                if (item.Name.toLowerCase().includes(filter))
                    object = item.Name.toLowerCase().includes(filter)
                else
                    object = item.ContactName.toLowerCase().includes(filter)
                return object;
            });
        }
        return newList;
    }

    handleChangeSearchBar(e) {
        this.localstate.filter = e.target.value;
        /* let currentList = SupplierStore.list;
        let newList = [];

        if (e.target.value !== "") {
            newList = currentList.filter(item => {
                const filter = e.target.value.toLowerCase();
                let object = null;
                if (item.Name.toLowerCase().includes(filter))
                    object = item.Name.toLowerCase().includes(filter)
                else
                    object = item.ContactName.toLowerCase().includes(filter)
                return object;
            });
        } else
            newList = SupplierStore.list;
        this.localstate.filtered = newList; */
    }

    render() {
        const {showModal, supplier, filtered} = this.localstate;
        return (
        <div>
            <Modal show={showModal} onClose={this.closeModal.bind(this)} closeOnBlur={true}>
                <Modal.Content style={styles.innerModal}>
                   <SupplierEdit supplier={supplier}  onSave={this.closeModal.bind(this)} />
                </Modal.Content>
            </Modal>
            <NavigationBar title='Lieferanten'>
                <input column="left" type="text" className="input" onChange={(e) => this.handleChangeSearchBar(e)} placeholder="Suche..." />
                <Button column="right" onClick={this.editSupplier.bind(this)}> <IconWithText icon={<FaPlusSquare/>} text='Lieferant hinzufügen'/></Button>
            </NavigationBar>
            <Container>
                <Section>
                <FetchView store={SupplierStore}>
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell align="left">Language</TableCell>
                                <TableCell align="left">Contact Person</TableCell>
                                <TableCell align="right">Contact Mail</TableCell>
                                <TableCell align="right">Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                    {this.applyFilters(SupplierStore.list).map(supplier => {
                        return (
                            <TableBody key={supplier.id}>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            /* indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            onChange={onSelectAllClick}
                                            inputProps={{ 'aria-label': 'select all desserts' }} */
                                        />
                                    </TableCell>
                                    <TableCell>{supplier.Name}</TableCell>
                                    <TableCell align="left">{supplier.Language}</TableCell>
                                    <TableCell align="left">{supplier.ContactName}</TableCell>
                                    <TableCell align="right">{supplier.ContactMail}</TableCell>
                                    <TableCell align="right">
                                        <Button onClick={() => this.editSupplier(supplier)}><FaPen/></Button>
                                        <Button onClick={() => this.deleteSupplier(supplier)}><FaTrash/></Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                        )
                    })}
                    </Table>
                </FetchView>
                </Section>
            </Container>
        </div>);
    }


}

const styles = {
    innerModal: {
        backgroundColor: 'white'
    }
}


export default SupplierScreen;